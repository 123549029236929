import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls"
const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: "",
        avatar: "",
        username: "",
        buttons: [],
        menu: [],//菜单
        type: '',
    },
    mutations: {
        login(state, payload) {
            state.token = payload.token;
        },
        logout(state) {
            state.token = "";
			window.location.href = '/'
        },
        setAdminInfo(state, payload) {
            state.avatar = payload.avatar;
            state.username = payload.username;
            state.type = payload.type;//1供应商，2商家
        },
        setButtons(state, payload) {
            state.buttons = payload;
        },
        setMenu(state, payload) {
            state.menu = payload;
        }
    },
    actions: {
        setMenu(ctx, menus) {
            ctx.commit('setMenu', menus)
        }
    },
    modules: {
    },
    getters: {
        is_self: () => {
            return {
                0: '非自营',
                1: '自营'
            }
        },
        settle: () => {
            return {
                0: '不需要结算',
                1: '需要结算'
            }
        },
        status: () => {
            return {
                1: '启用',
                2: '禁用'
            }
        },
        rule_type: () => {
            //0平台 1供应商 2商圈
            return {
                0: '平台',
                1: '供应商',
                2: '商圈'
            }
        },
        order_type: () => {
            //1商品订单 2到店支付订单
            return {
                1: '商品订单',
                2: '到店支付订单',
                3: '外卖订单',
				4: '自提订单',
				5: '团购订单'
            }
        },
        supermarket_brand_list: () => {
            //1华达 2日新 3其他
            return {
                1: '华达',
                2: '日新',
                3: '其他'
            }
        }
    },
    plugins: [
        createPersistedState({
            key: 'token',
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
    ]
})
